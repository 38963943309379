



















































































import ModuleBuilder from '@/includes/logic/Modules/modules/ModuleBuilder'
import { findGroupByValue } from '@/includes/logic/Modules/ModulesGroupsUtil'

import Icon from 'piramis-base-components/src/components/Icon/Icon.vue'
import Tags from 'piramis-base-components/src/components/Tags/Tags.vue'

import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import ModulesApi from "@/includes/Api/Modules.api";

@Component({
  components: {
    Icon,
    Tags
  },
  data() {
    return {
      findGroupByValue
    }
  }
})
export default class ModuleCard extends Vue {
  @Prop() moduleBuilder!: ModuleBuilder

  @Prop({ type: Boolean, default: false }) loading!: boolean

  @Prop({ type: Boolean, required: false, default: false }) showGroup!: boolean

  get group() {
    return findGroupByValue(this.moduleBuilder.group)
  }

  get tags() {
    const tags = this.moduleBuilder.tags()

    if (Array.isArray(tags) && tags.length) {
      return tags
    }

    return []
  }

  get tariffTags() {
    const tags = this.moduleBuilder.tariffTags()

    if (Array.isArray(tags) && tags.length) {
      return tags
    }

    return []
  }

  get existedDescription() {
    if (this.$te(this.moduleBuilder.model.miniDescriptionKey)) {
      return this.$t(this.moduleBuilder.model.miniDescriptionKey)
    } else if (this.$te(this.moduleBuilder.model.descriptionKey)) {
      return this.$t(this.moduleBuilder.model.descriptionKey)
    } else {
      return ''
    }
  }
}
