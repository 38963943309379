























import { BaseNetworkData } from "@/includes/types/networks";
import { getNetworkTitle } from "../../includes/logic/Networks/utils";
import NetworksHelperMixin from "@/includes/logic/Networks/NetworksHelperMixin";

import { Component, Prop, Mixins } from 'vue-property-decorator';

export type NetworkChatEntitiesData = Array<{
  network: Pick<BaseNetworkData, 'id'| 'name'> & Partial<Omit<BaseNetworkData, 'id'| 'name'>>,
  data: any
}>

@Component({
  methods: {
    getNetworkTitle
  }
})
export default class NetworkChatEntities extends Mixins(NetworksHelperMixin) {
  @Prop() networks!: NetworkChatEntitiesData
}
