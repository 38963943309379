import ModuleBuilder from "@/includes/logic/Modules/modules/ModuleBuilder"
import { ModuleManagerState } from "@/includes/logic/Modules/ModulesManager";
import NewUserDeprecatedTriggersHandlerModule
  from "@/includes/logic/Modules/modules/modules/NewUserDeprecatedTriggersHandlerModule/NewUserDeprecatedTriggersHandlerModule";
import { CreateTypeEnum, isChatManager, ModuleGroupEnum } from "@/includes/logic/Modules/types";
import { errorNotification, successNotification } from "@/includes/NotificationService";
import ModuleActionsMixin from "@/includes/logic/Modules/mixins/ModuleActionsMixin";
import { stringIsModuleGroupEnum } from "@/includes/logic/Modules/ModulesGroupsUtil";
import ModulesRoutesHelperMixin from "@/includes/logic/Modules/mixins/ModulesRoutesHelperMixin";
import ModulesApi from "@/includes/Api/Modules.api";
import { RelatedItem } from "@/includes/types/ModuleRelatedSuggestion";

import { Component, Mixins, Watch } from "vue-property-decorator";
import { Modal } from "ant-design-vue";
import { debounce } from "lodash";
import { Guid } from "guid-typescript";

@Component
export default class ModulesListMixin extends Mixins(ModuleActionsMixin, ModulesRoutesHelperMixin) {
  currentGroup: ModuleGroupEnum | null = null

  searchQuery = ''

  @Watch('searchQuery')
  onSearchQueryChange = debounce((value: string) => {
    if (this.isChatManagerVm) {
      const onlyLetters = value
          .replace(/[^a-zа-я]+/gi, ' ')
          .replace(/\s+/g, ' ')
          .trim()

      ModulesApi.saveSearchContent(this.$store.getters.chatId, onlyLetters)
          .catch(errorNotification)
    }
  }, 1000)

  @Watch('searchQuery')
  onQueryChange(val: string) {
    const trimmedQuery = this.trimAndLower(val)

    if (trimmedQuery) {
      const r = this.nonModulesRelated.find(r => this.exactSearchResultsExists(r.aliases, trimmedQuery))

      if (r && !this.nonModulesRelatedActive.some(ra => ra.guid === r.guid)) {
        this.nonModulesRelatedActive.push(r)
      }

      this.nonModulesRelatedActive = this.nonModulesRelatedActive.filter(ra => this.exactSearchResultsExists(ra.aliases, trimmedQuery))
    } else {
      this.nonModulesRelatedActive = []
    }
  }

  nonModulesRelated: Array<RelatedItem> = [
    {
      guid: Guid.create().toString(),
      aliases: [ 'бренд' ],
      title: this.$t('related_item_brand').toString()
    },
    {
      guid: Guid.create().toString(),
      aliases: [ 'spammerdetected', 'spam', 'спа', 'спам', 'спам детектор', '!анти спам', 'анти', 'система' ],
      title: this.$t('related_item_psd').toString()
    },
    {
      guid: Guid.create().toString(),
      aliases: [ 'триге', '!триггер' ],
      title: this.$t('related_item_trigger').toString()
    },
  ]

  nonModulesRelatedActive: Array<RelatedItem> = []

  trimAndLower = (value: string) => value.toLowerCase().trim()

  get allModules(): Array<ModuleBuilder> {
    if (ModuleManagerState) {
      return ModuleManagerState.allModuleBuilders()
          .filter(m => !(m.model instanceof NewUserDeprecatedTriggersHandlerModule))
    }

    return []
  }

  get filteredAllModules() {
    const moduleTypeExistFilter = (m: ModuleBuilder) => {
      const module = ModuleManagerState!.getModuleByCriteria('type', m.model.type)

      return this.isChatManagerVm ? module && module.config.create_type === CreateTypeEnum.Network || !module : !module
    }

    const groupFilter = (m: ModuleBuilder) => m.group === this.currentGroup

    const hasActiveUltimate = this.$store.getters.isChatLicenseExists && this.$store.getters.getHaveLicense

    if (this.currentGroup) {
      // if (hasActiveUltimate) {
      //   return this.modules.filter(groupFilter)
      // } else {
      return this.allModules
          .filter(moduleTypeExistFilter)
          .filter(groupFilter)
      // }
    } else {
      // if (hasActiveUltimate) {
      //   return this.modules
      // } else {
      return this.allModules
          .filter(moduleTypeExistFilter)
      // }
    }
  }

  exactSearchResultsExists(source: Array<string>, query: string) {
    return source.map(this.trimAndLower).some(a => a.includes(query))
  }

  queriedModules(modulesToQuery?: Array<ModuleBuilder>) {
    let modules = modulesToQuery ?? this.filteredAllModules

    const trimmedQuery = this.trimAndLower(this.searchQuery)

    if (trimmedQuery) {
      modules = modules.filter(m =>
          this.trimAndLower(this.$t(m.model.titleKey).toString()).includes(trimmedQuery)
          || m.aliases && this.exactSearchResultsExists(m.aliases, trimmedQuery)
      )
    }

    if (this.currentGroup) {
      modules = modules.filter(m => m.group === this.currentGroup)
    }

    return modules
  }

  saveModule() {
    if (ModuleManagerState && ModuleManagerState.currentModule) {
      return ModuleManagerState.saveModule(ModuleManagerState.currentModule)
    }

    return Promise.resolve()
  }

  get isChatManagerVm() {
    return isChatManager(ModuleManagerState)
  }

  get chatId() {
    if (this.isChatManagerVm) {
      return this.$store.getters.isChatSet && this.$store.getters.chatId
    } else {
      const network = this.$store.state.networksState.currentNetwork

      return network && network.chats.length && network.chats[0]
    }
  }

  moduleLoading: ModuleBuilder['model']['guid'] | null = null

  onAddModule(moduleBuilder: ModuleBuilder) {
    if (!this.moduleLoading) {
      this.moduleLoading = moduleBuilder.model.guid

      this.addModule(moduleBuilder, this.chatId)
          .then((builder) => {
            if (builder) {
              if (builder.hasEmptyParams()) {
                ModuleManagerState?.setCurrentModule(builder.model)

                this.saveModule()
                    .then((res) => {
                      if (res) {
                        successNotification(this.$t('module_notification_module_add').toString())

                        ModuleManagerState?.clearCurrentModule()
                        Modal.destroyAll()

                        this.moduleLoading = null
                        this.gotoActiveModules()
                      }
                    })
              } else {
                this.moduleLoading = null

                this.gotoCurrentModule(builder.model.type)
              }
            } else {
              this.moduleLoading = null
            }
          })
    }
  }

  created() {
    const query = this.$route.params?.tab?.toString()

    if (stringIsModuleGroupEnum(query)) {
      this.currentGroup = query as ModuleGroupEnum
    }
  }
}
