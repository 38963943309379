import ModuleBuilder from "@/includes/logic/Modules/modules/ModuleBuilder"
import { ModuleManagerState } from "@/includes/logic/Modules/ModulesManager";
import { successNotification } from "@/includes/NotificationService";
import BaseModule from "@/includes/logic/Modules/modules/BaseModule";
import { BaseChatInfo } from "@/includes/types/Chat/types";

import { Component } from "vue-property-decorator";
import Vue from "vue";

@Component({})
export default class ModuleActionsMixin extends Vue {

  removeModule(module: ModuleBuilder) {
    return new Promise<void>((resolve) => {
      this.$confirm({
        title: this.$t('module_confirm_remove_module_title').toString(),
        content: this.$t('module_confirm_remove_module_content').toString(),
        icon: 'close',
        onOk: () => {
          if (ModuleManagerState) {
            ModuleManagerState.removeModule(module)
                .then((res) => {
                  if (res) {
                    successNotification(this.$t('module_notification_removed_success').toString())
                  }
                })
                .finally(() => resolve())
          }
        }
      })

    })
  }

  async addModule(module: ModuleBuilder, chatId: BaseChatInfo['chat_id']) {
    if (ModuleManagerState) {
      return ModuleManagerState.getDefaultModule(chatId, module.model.type)
          .then((model) => {
            if (model) {
              ModuleManagerState!.setCurrentModule(model)

              return ModuleManagerState!.currentModule
            }
          })
    }
  }

  async moduleSetting(module: BaseModule) {
    if (ModuleManagerState) {
      ModuleManagerState.setCurrentModule(module);

      return ModuleManagerState.currentModule
    }
  }

}
